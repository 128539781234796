import { dataTheme, Feedback, LayoutContext } from "@alb/live-lib";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { configure } from "axios-hooks";
import { Suspense, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { sidebar } from "layouts/layout.sidebar";

import { api } from "./providers/AxiosProvider";
import { router } from "./routes/routes";
import { RouterProvider } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import Loading from "components/Utils/Loading";

// loading component for suspense fallback (i18n)
const Loader = () => <Loading />;

const App = () => {
  const authCtx = useAuth();
  const { addSidebar } = useContext(LayoutContext);
  const { t } = useTranslation();

  useEffect(() => {
    configure({ axios: api });
  }, []);

  const logoutHandler = () => {
    // console.log("logoutHandler...", authCtx);
    authCtx.logout();
  };

  useEffect(() => {    
    addSidebar(sidebar(t, authCtx.auth, logoutHandler));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCtx.auth]);

  // const history = createBrowserHistory();

  return (
    <ThemeProvider theme={dataTheme}>
      <CssBaseline />
      <Suspense fallback={<Loader />}>
        <Feedback>
          {/* <AppRoutes history={history} /> */}
          <RouterProvider router={router} />;
        </Feedback>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
