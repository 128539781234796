import { BackToButton, LayoutContext } from "@alb/live-lib";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import EntityInfo from "components/EntityInfo/EntityInfo";
import EntityInfoItem from "components/EntityInfo/EntityInfoItem";
import FilterSelect from "components/Utils/FilterSelect";
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGet } from "hooks/fetchData/useGet";
import usePrevLocation from "hooks/usePrevLocation";
import { ISelectOption, IStream } from "interfaces";
import { RoutePaths } from "routes/routes";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate, formatRoutePath } from "utils";

import StreamsEdit from "./StreamsEdit";

const StreamsView = () => {
	let params = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const prevLocation = usePrevLocation(RoutePaths.STREAMS);

	const { addHeader, addAction, action } = useContext(LayoutContext);

	const { data: stream, loading, refetch } = useGet<IStream>(
		ServiceApiUrl.streamURL,
		params.id
	);

	const handleCloseEdit = () => {
		addAction(false);
	};
	const handleEditSuccess = () => {
		handleCloseEdit();

		// update list
		refetch();
	};

	const handleSelectedStream = (option: ISelectOption | null) => {
		if (!option) return undefined;

		const path = formatRoutePath(location, params, {
			id: option.value,
		});

		navigate(path);
	};

	const header = {
		title: stream?.name,
		backTo: (
			<BackToButton title={t("common.goBack")} onClick={() => navigate(prevLocation)} />
		),
		select: (stream && <FilterSelect<IStream> apiUrl={ServiceApiUrl.streamURL} selectedValue={stream?.id} onSelected={handleSelectedStream} />),
		action: (
			<Button
				variant="contained"
				disableElevation
				startIcon={<EditIcon />}
				onClick={() => addAction(true)}
			>
				{t("streams.editStream")}
			</Button>
		),
	};

	useEffect(() => {
		if (!stream?.name) return;
		addHeader(header);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stream?.name]);

	let streamView = <NoData />;

	if (loading) {
		streamView = <Loading />;
	}

	if (stream) {
		streamView = (
			<EntityInfo title={t("common.details")}>
				<EntityInfoItem
					fitContent={true}
					label={t("streams.form.nameLabel")}
					value={stream.name}
				/>

				<EntityInfoItem
					label={t("streams.form.isActiveLabel")}
					value={stream.is_active ? t("common.active") : t("common.inactive")}
				/>

				<EntityInfoItem
					fitContent={true}
					label={t("streams.form.descriptionLabel")}
					value={stream.description}
				/>

				<EntityInfoItem
					fitContent={true}
					label={t("streams.form.categoryLabel")}
					value={stream.category.name}
				/>

				<EntityInfoItem
					fitContent={true}
					label={t("streams.form.adapterLabel")}
					link={`${RoutePaths.ADAPTERS}/${stream.adapter_configurator.id}`}
					value={stream.adapter_configurator.name}
				/>

				<EntityInfoItem
					label={t("common.createdAt")}
					value={formatDate(
						stream.created_at,
						t("calendar.dateTimeFormatLocal")
					)}
				/>
				<EntityInfoItem
					label={t("common.updatedAt")}
					value={formatDate(
						stream.updated_at,
						t("calendar.dateTimeFormatLocal")
					)}
				/>
			</EntityInfo>
		);
	}

	let streamEdit;
	if (action) {
		streamEdit = (
			<StreamsEdit
				open={action}
				data={stream}
				onClose={handleCloseEdit}
				onSuccess={handleEditSuccess}
			/>
		);
	}


	return <>{streamView}{streamEdit}</>;
};

export default StreamsView;
