import { BackToButton, LayoutContext } from "@alb/live-lib";
import EditIcon from "@mui/icons-material/Edit";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button } from "@mui/material";
import { Box, Tab } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import EntityInfo from "components/EntityInfo/EntityInfo";
import EntityInfoItem from "components/EntityInfo/EntityInfoItem";
import FilterSelect from "components/Utils/FilterSelect";
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { TabPanelContainer } from "components/Utils/Tab/TabPanelContainer";
import { useGetAll } from "hooks/fetchData/useGetAll";
import usePrevLocation from "hooks/usePrevLocation";
import { IAdapter, IAdapterDevices, ISelectOption } from "interfaces";
import { RoutePaths } from "routes/routes";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, formatDate, formatRoutePath } from "utils";

import DevicesTabList from "../Devices/DevicesTabList";
import StreamsTabList from "../Streams/StreamsTabList";
import AdaptersEdit from "./AdaptersEdit";

const AdaptersView = () => {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

	const prevLocation = usePrevLocation(RoutePaths.ADAPTERS);

  const { addHeader, addAction, action } = useContext(LayoutContext);

  const { data: adapter, loading, refetch } = useGetAll<IAdapterDevices>(
    ServiceApiUrl.getAdapterDevicesURL(params.id || "")
  );

  const handleSelectedAdapter = (option: ISelectOption | null) => {
    if(!option) return undefined

    const path = formatRoutePath(location, params, {
      id: option.value,
    });

    navigate(path);
  };

  const handleCloseEdit = () => {
    addAction(false);
  };
  const handleEditSuccess = () => {
    handleCloseEdit();

    // update list
    refetch();
  };

  const header = {
    title: adapter?.name,
    backTo: (
      <BackToButton title={t("common.goBack")} onClick={() => navigate(prevLocation)} />
    ),
    select: (adapter && <FilterSelect<IAdapter> apiUrl={ServiceApiUrl.adapterURL} selectedValue={adapter?.id} onSelected={handleSelectedAdapter} />),
    action: (
      <Button
        variant="contained"
        disableElevation
        startIcon={<EditIcon />}
        onClick={() => addAction(true)}
      >
        {t("adapters.editAdapter")}
      </Button>
    ),
  };

  useEffect(() => {
		if (!adapter?.name) return;
    addHeader(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapter?.name]);

  let adapterView = <NoData />;
  let adapterDevices = <NoData />;
  let adapterStreams = <NoData />;

  if (loading) {
    adapterView = <Loading />;
    adapterDevices = <Loading />;
    adapterStreams = <Loading />;
  }

  if (adapter) {
    adapterView = (
      <EntityInfo title={t("common.details")}>
        <EntityInfoItem
          fitContent={true}
          label={t("adapters.form.nameLabel")}
          value={adapter.name}
        />

        <EntityInfoItem
          label={t("accounts.form.isActiveLabel")}
          value={adapter.is_active ? t("common.active") : t("common.inactive")}
        />

        <EntityInfoItem
          fitContent={true}
          label={t("accounts.form.descriptionLabel")}
          value={adapter.description}
        />
        <EntityInfoItem
          label={t("adapters.form.protectedIngestionAtLabel")}
          value={
            adapter.protected_ingestion
              ? t("common.active")
              : t("common.inactive")
          }
        />
        <EntityInfoItem
          label={t("adapters.form.domainLabel")}
          value={adapter.domain}
        />
        <EntityInfoItem
          label={t("adapters.form.domainDevoLabel")}
          value={adapter.domain_devo}
        />
        <EntityInfoItem
          label={t("adapters.form.partnerLabel")}
          value={adapter.partner.name}
        />
        <EntityInfoItem
          label={t("adapters.form.partnerDevoLabel")}
          value={adapter.partner_devo}
        />
        <EntityInfoItem
          label={t("adapters.form.tenantLabel")}
          value={adapter.tenant.name}
        />
        <EntityInfoItem
          label={t("adapters.form.gatewayUrlLabel")}
          value={adapter.gateway_url}
        />
        <EntityInfoItem
          label={t("adapters.form.gatewayUserLabel")}
          value={adapter.gateway_user}
        />

        <EntityInfoItem
          label={t("common.createdAt")}
          value={formatDate(
            adapter.created_at,
            t("calendar.dateTimeFormatLocal")
          )}
        />
        <EntityInfoItem
          label={t("common.updatedAt")}
          value={formatDate(
            adapter.updated_at,
            t("calendar.dateTimeFormatLocal")
          )}
        />
      </EntityInfo>
    );

    if (!arrayIsEmpty(adapter.devices))
      adapterDevices = <DevicesTabList devices={adapter.devices} />;
    if (!arrayIsEmpty(adapter.streams))
      adapterStreams = <StreamsTabList streams={adapter.streams} />;
  }

  let adapterEdit;
  if (action) {
    adapterEdit = (
      <AdaptersEdit
        open={action}
        data={adapter}
        onClose={handleCloseEdit}
        onSuccess={handleEditSuccess}
      />
    );
  }

  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      {adapterView}
      {adapterEdit}

      <Box mt={2}>
        <TabContext value={value}>
          <TabList variant="fullWidth" onChange={handleChange}>
            <Tab label={t("adapters.devices")} value="1" />
            <Tab label={t("adapters.streams")} value="2" />
          </TabList>

          <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
            <TabPanelContainer>{adapterDevices}</TabPanelContainer>
          </TabPanel>
          <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="2">
            <TabPanelContainer>{adapterStreams}</TabPanelContainer>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default AdaptersView;
