import React from "react";
import PropTypes from "prop-types";
import { styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

export const EntityContainer = styled(Grid)<any>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
  borderRadius: 12,
  padding: theme.spacing(3),
}));

const EntityInfo = (props: { title: string; children?: React.ReactNode }) => {
  const { title, children } = props;

  return (
    <>
      <EntityContainer mt={5}>
        <Grid xs={true}>
          <Typography variant="h5" className="bold">
            {title}
          </Typography>
        </Grid>
        <Grid container spacing={2} pt={3} pb={2}>
          {children}
        </Grid>
      </EntityContainer>
    </>
  );
};

EntityInfo.propTypes = {
  title: PropTypes.string,
};

export default EntityInfo;
