import { BackToButton, LayoutContext } from "@alb/live-lib";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import EntityInfo from "components/EntityInfo/EntityInfo";
import EntityInfoItem from "components/EntityInfo/EntityInfoItem";
import FilterSelect from "components/Utils/FilterSelect";
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGet } from "hooks/fetchData/useGet";
import { ISelectOption, ITenant } from "interfaces";
import { RoutePaths } from "routes/routes";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate, formatRoutePath } from "utils";

import TenantsEdit from "./TenantsEdit";

const TenantsView = () => {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { addHeader, addAction, action } = useContext(LayoutContext);

  const {
    data: tenant,
    loading,
    refetch,
  } = useGet<ITenant>(ServiceApiUrl.tenantURL, params.id);

  const handleCloseEdit = () => {
    addAction(false);
  };
  const handleEditSuccess = () => {
    handleCloseEdit();

    // update list
    refetch();
  };

  const handleSelectedTenant = (option: ISelectOption|null) => {
    if (!option) return undefined

    const path = formatRoutePath(location, params, {
      id: option.value,
    });

    navigate(path);
  };

  const header = {
    title: tenant?.name,
    backTo: (
      <BackToButton title={t("common.goBack")} onClick={() => navigate(RoutePaths.TENANTS)} />
    ),
    select: (tenant && <FilterSelect<ITenant> apiUrl={ServiceApiUrl.tenantURL} selectedValue={tenant?.id} onSelected={handleSelectedTenant} />),
    action: (
      <Button
        variant="contained"
        disableElevation
        startIcon={<EditIcon />}
        onClick={() => addAction(true)}
      >
        {t("tenants.editTenant")}
      </Button>
    ),
  };

  useEffect(() => {
		if (!tenant?.name) return;
    addHeader(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant?.name]);

  let tenantView = <NoData />;

  if (loading) {
    tenantView = <Loading />;
  }

  if (tenant) {
    tenantView = (
      <EntityInfo title={t("common.details")}>
        <EntityInfoItem
          fitContent={true}
          label={t("tenants.form.nameLabel")}
          value={tenant.name}
        />
        <EntityInfoItem
          label={t("tenants.form.isActiveLabel")}
          value={tenant.is_active ? t("common.active") : t("common.inactive")}
        />

        <EntityInfoItem
          label={t("common.createdAt")}
          value={formatDate(
            tenant.created_at,
            t("calendar.dateTimeFormatLocal")
          )}
        />
        <EntityInfoItem
          label={t("common.updatedAt")}
          value={formatDate(
            tenant.updated_at,
            t("calendar.dateTimeFormatLocal")
          )}
        />
      </EntityInfo>
    );
  }

  let tenantEdit;
  if (action) {
    tenantEdit = (
      <TenantsEdit
        open={action}
        data={tenant}
        onClose={handleCloseEdit}
        onSuccess={handleEditSuccess}
      />
    );
  }

  return (
    <>
      {tenantView}
      {tenantEdit}
    </>
  );
};

export default TenantsView;
