import { useNotification } from "@alb/live-lib";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useUpdate } from "hooks/fetchData/useUpdate";
import { useGet } from "hooks/fetchData/useGet";
import { IDevice, IDeviceForm } from "interfaces";
import { IDialogProps } from "interfaces/IDialogProps";
import { ServiceApiUrl } from "services/ServiceApiUrl";

import DevicesForm from "./DevicesForm";
import Loading from "components/Utils/Loading";

const DevicesEdit = ({ open, data, onClose, onSuccess }: IDialogProps<IDevice>) => {
	const { t } = useTranslation();
	const { addNotification } = useNotification();
	const editFormId = "edit-device-form";

	const { data: device, loading: loadingView } = useGet<IDevice>(
		ServiceApiUrl.deviceURL,
		data?.id
	);

	//pedido para editar device
	const {
		loading,
		refetch: updateDevice,
	} = useUpdate<IDevice>(ServiceApiUrl.deviceURL, data?.id);

	// sucesso
	const handlerSuccess = () => {
		onClose && onClose(); //fecha modal
		addNotification({
			message: t("devices.deviceEdited"),
			severity: "success",
		});
		onSuccess && onSuccess();
	};

	const formSubmitHandler = async (payLoad: IDeviceForm<string, number[]>) => {
		// console.log(payLoad);
		try {
			await updateDevice({ data: payLoad });
			await handlerSuccess();
		} catch (error) { }
	};

	return (
		<>
			<Dialog maxWidth="md" open={open} onClose={onClose}>
				<DialogTitle>
					<Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
						{t("devices.editDevice")}
					</Typography>
				</DialogTitle>

				<DialogContent>
					{loadingView && <Loading />}
					{!loadingView && device &&
						<DevicesForm formId={editFormId} data={device} onFormSubmit={formSubmitHandler} />
					}
				</DialogContent>

				{!loading && device &&
					<DialogActions>
						<Button variant="outlined" color="primary" onClick={onClose}>
							{t("common.cancel")}
						</Button>

						<LoadingButton
							loading={loading}
							variant="contained"
							color="primary"
							disabled={false}
							type={"submit"}
							form={editFormId}
						>
							{t("common.edit")}
						</LoadingButton>
					</DialogActions>
				}
			</Dialog>
		</>
	);
};

export default DevicesEdit;
