import { InputAutocomplete, InputCheckbox, InputText } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { IAdapter, IApiResponse, ICategory, ISelectOption, IStream, IStreamForm } from "interfaces";
import React, { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils";

import { formDefaultValues } from "./formDefaultValues";
import { getStreamFormSchema } from "./validations";

const StreamsForm = (props: {
  formId: string;
  data?: IStream;
  onFormSubmit: (payLoad: IStreamForm<string>) => void;
}) => {
  const { t } = useTranslation();

  const { formId, data, onFormSubmit } = props;

  const defaultValues = formDefaultValues(data);

  const defaultSelect: ISelectOption[] = [];
  // get list of adapters
  const { data: adapters, loading: loadingAdapters } = useGetAll<IApiResponse<IAdapter>>(
    ServiceApiUrl.adapterURL
  );
  // get list of categories
  const { data: categories, loading: loadingCategories } = useGetAll<IApiResponse<ICategory>>(
    ServiceApiUrl.categoryURL
  );

  const [adaptersOptions, setAdaptersOptions] =
    useState<ISelectOption[]>(defaultSelect);
  useEffect(() => {
    if (adapters) {
      const aOpts = adapters.data.map((p: IAdapter) => ({
        label: p.name,
        value: p.id,
      }));
      setAdaptersOptions(aOpts);
    }
  }, [adapters]);

  const [categoriesOptions, setCategoriesOptions] =
    useState<ISelectOption[]>(defaultSelect);
  useEffect(() => {
    if (categories) {
      const aOpts = categories.data.map((p: ICategory) => ({
        label: p.name,
        value: p.id,
      }));
      setCategoriesOptions(aOpts);
    }
  }, [categories]);

  const methodsForm = useForm<IStreamForm>({
    defaultValues: defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(getStreamFormSchema(t('streams.form.nameValidationMessage'))),
  });

  const { control, handleSubmit, setValue } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IStreamForm> = (payLoad: IStreamForm) => {
    const payLoadAPI = payLoad as unknown as IStreamForm<string>;

    payLoadAPI.adapter_configurator = payLoad.adapter_configurator?.value || '';
    payLoadAPI.category = payLoad.category?.value || '';

    onFormSubmit(payLoadAPI);
  };

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("streams.form.nameLabel")}
              </Typography>
              <InputText
                control={control}
                autoFocus={true}
                name="name"
                placeholder={t("streams.form.namePlaceholder")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("streams.form.descriptionLabel")}
              </Typography>
              <InputText
                control={control}
                autoFocus={true}
                name="description"
                placeholder={t("streams.form.descriptionPlaceholder")}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="h6">
                {t("streams.form.unitLabel")}
              </Typography>
              <InputText
                control={control}
                autoFocus={true}
                name="unit"
                placeholder={t("streams.form.unitPlaceholder")}
              />
            </Grid>

            <Grid item xs={8}>
              <Typography gutterBottom variant="h6">
                {t("streams.form.categoryLabel")}
              </Typography>
              {!loadingCategories && !arrayIsEmpty(categoriesOptions) && (
                <InputAutocomplete
                  control={control}
                  name="category"
                  options={categoriesOptions}
                  placeholder={t("streams.form.categoryPlaceholder")}
                />
              )}
            </Grid>

            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("streams.form.adapterLabel")}
              </Typography>
              {!loadingAdapters && !arrayIsEmpty(adaptersOptions) && (
                <InputAutocomplete
                  control={control}
                  name="adapter_configurator"
                  options={adaptersOptions}
                  placeholder={t("streams.form.adapterPlaceholder")}                  
                />
              )}
            </Grid>

            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("streams.form.isActiveLabel")}
              </Typography>
              <InputCheckbox
                control={control}
                name="is_active"
                setValue={setValue}
                checked={defaultValues.is_active}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default StreamsForm;
