import { LayoutContext } from '@alb/live-lib'
import { BackToButton } from '@alb/live-lib'
import { Button } from '@mui/material'
import { useContext, useEffect } from 'react'

type Props = {}

const About = (props: Props) => {
  const header = {
    title: 'About',
    backTo: <BackToButton title="Go Back" onClick={() => alert()} />,
    action: (
      <Button
        variant="contained"
        disableElevation
        onClick={() => handleAction(true)}
      >
        Action
      </Button>
    ),
  }
  const { addHeader, addAction, action } = useContext(LayoutContext)

  const handleAction = (action: boolean) => {
    addAction(action)
  }

  useEffect(() => {
    handleAction(false)
    addHeader(header)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <div>{action ? 'Action CLick' : 'About Page'}</div>
}

export default About
