import { Link } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { ChipStatus } from "components/Utils/ChipStatus";
import { IDevice } from "interfaces/IDevice";
import { RoutePaths } from "routes/routes";
import { CustomTablePagination, formatDate, ROWS_PER_PAGE, statusColor } from "utils";

const DevicesTabList = (props: { devices: IDevice[] }) => {
	const { t } = useTranslation();
	const { devices } = props;
	const [page, setPage] = useState(1);

	let location = useLocation();
	const state = { 'from': location };

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage);
	};

	const row = devices.slice((page-1) * ROWS_PER_PAGE, (page-1) * ROWS_PER_PAGE + ROWS_PER_PAGE).map((device: IDevice) => (
		<TableRow key={device.id}>
			<TableCell>
				<Link
					underline="none"
					component={RouterLink}
					state={state}
					to={`${RoutePaths.DEVICES}/${device.id}`}
				>
					{device.name}
				</Link>
			</TableCell>
			<TableCell>
				<ChipStatus
					label={device.is_active ? t("common.active") : t("common.inactive")}
					color={
						device.is_active
							? statusColor("active")
							: statusColor("non-active")
					}
				/>
			</TableCell>
			<TableCell>
				{formatDate(device.created_at, t("calendar.dateTimeFormatLocal"))}
			</TableCell>
			<TableCell>
				{formatDate(device.updated_at, t("calendar.dateTimeFormatLocal"))}
			</TableCell>
		</TableRow>
	));

	return (
		<>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t("common.name")}</TableCell>
							<TableCell>{t("common.statusLabel")}</TableCell>
							<TableCell>{t("common.createdAt")}</TableCell>
							<TableCell>{t("common.updatedAt")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>{row}</TableBody>
				</Table>
			</TableContainer>
			<CustomTablePagination
				count={devices.length}
				page={page}
				onPageChange={handleChangePage}
			/>
		</>
	);
};

export default DevicesTabList;
