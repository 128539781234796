import { useNotification } from "@alb/live-lib";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useCreate } from "hooks/fetchData/useCreate";
import { IAdapter, IAdapterForm } from "interfaces";
import { IDialogProps } from "interfaces/IDialogProps";
import { ServiceApiUrl } from "services/ServiceApiUrl";

import AdaptersForm from "./AdaptersForm";

const AdaptersAdd = ({ open, onClose, onSuccess }: IDialogProps) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const addFormId = "add-adapter-form";

  //pedido para criar adapter
  const {
    loading,
    refetch: createAdapter,
  } = useCreate<IAdapter>(ServiceApiUrl.adapterURL);

  // sucesso
  const handlerSuccess = () => {
    onClose && onClose(); //fecha modal
    addNotification({
      message: t("adapters.adapterAdded"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  const formSubmitHandler = async (payLoad: IAdapterForm) => {
    // console.log(payLoad);
    try {
      await createAdapter({ data: payLoad });
      await handlerSuccess();
    } catch (error) {}
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
          {t("adapters.addAdapter")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <AdaptersForm formId={addFormId} onFormSubmit={formSubmitHandler} />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={false}
          type={"submit"}
          form={addFormId}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AdaptersAdd;
