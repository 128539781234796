import { useNotification } from "@alb/live-lib";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useErrorHandler = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  const sendErrorMessage = useCallback((err: any) => {
    let msg = t("errors.unknow");

    try {
      msg = err?.response?.data.map((err: any, index: number) => (
        <span key={`err_${err.error_code}_${index}`}>
          {t(`errors.${err.error_code}`) + "\n"} 
          {err.detail_error && <><br /><span className="small">{JSON.stringify(err.detail_error)}</span></>}
          <br />
        </span>
      ));
    } catch (error: any) {      
      msg = `${msg} (${err.message || ''} - ${error})`;      
    }

    return addNotification({ message: msg || err.message, severity: "error" });    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { sendErrorMessage };
};
