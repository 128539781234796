import { ConfirmDialog, TableHeadCell, TableHeadSort, useNotification } from "@alb/live-lib";
import { Table, TableBody, TableContainer } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useDelete } from "hooks/fetchData/useDelete";
import { IPartner } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, formatRoutePath, IResultTable, CustomTablePagination } from "utils";

import PartnersEdit from "./PartnersEdit";
import PartnerTableRow from "./PartnersTableRow";

export const PartnersTable = (props: IResultTable) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: partners,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    page,
    setPage,
    refetch,
    loading
  } = props;

  const [selectedPartner, setSelectedPartner] = useState<IPartner>();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const tableHeads: TableHeadCell[] = [
    {
      id: "name",
      label: t("common.name"),
    },
    {
      id: "is_active",
      label: t("common.statusLabel"),
    },
    {
      id: "updated_at",
      label: t("common.updatedAt"),
    },
    {
      id: "action_cell",
      label: "",
      notSortable: true,
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleEdit = (row: IPartner) => {
    // console.log("Edit: ", row);
    setSelectedPartner(row);
    setOpenEdit(true);
  };
  const handleDelete = (row: IPartner) => {
    // console.log("Delete:", row);
    setSelectedPartner(row);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteConfirm(false);
    setSelectedPartner(undefined);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectedPartner(undefined);
  };
  const handleEditSuccess = () => {
    handleCloseEdit();

    // update list
    refetch && refetch();
  };

  const {
    loading: loadingDelete,
    refetch: deletePartner,
  } = useDelete<IPartner>(ServiceApiUrl.partnerURL, selectedPartner?.id);

  // delete sucesso
  const handlerDeleteSuccess = () => {
    handleCloseDelete();
    addNotification({
      message: t("partners.partnerDeleted"),
      severity: "success",
    });

    // update list
    refetch && refetch();
  };

  const handleConfirmDelete = async () => {
    // console.log("confirm delete!");
    try {
      await deletePartner();
      await handlerDeleteSuccess();
    } catch {}
  };

  const handleRowClick = (row: IPartner) => {
    // console.log('row click...', row)
    const path = formatRoutePath(location, params, {
      id: row.id
    });
    navigate(path);
  }

  let row;
  if (!arrayIsEmpty(partners.data)) {
    row = partners.data.map((partner: IPartner) => (
      <PartnerTableRow
        key={partner.id}
        row={partner}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onRowClick={handleRowClick}
      />
    ));
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHeadSort
            headCells={tableHeads}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            loading={loading}
          />
          <TableBody>{row}</TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        count={partners.totalCount}
        page={page}
				totalPages={partners.totalPages}
        onPageChange={handleChangePage}
      />

      {selectedPartner && (
        <>
          {openDeleteConfirm && (
            <ConfirmDialog
              open={openDeleteConfirm}
              title={t("partners.deleteConfirmTitle")}
              message={t("partners.deleteConfirmMessage")}
              actionConfirmText={t("common.delete")}
              actionCancelText={t("common.cancel")}
              loading={loadingDelete}
              onClose={handleCloseDelete}
              onConfirm={handleConfirmDelete}
            />
          )}

          {openEdit && (
            <PartnersEdit
              open={openEdit}
              data={selectedPartner}
              onClose={handleCloseEdit}
              onSuccess={handleEditSuccess}
            />
          )}
        </>
      )}
    </>
  );
};
