import { useNotification } from "@alb/live-lib";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useCreate } from "hooks/fetchData/useCreate";
import { IDevice, IDeviceForm } from "interfaces";
import { IDialogProps } from "interfaces/IDialogProps";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import DevicesForm from "./DevicesForm";

const DevicesAdd = ({ open, onClose, onSuccess }: IDialogProps) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const addFormId = "add-device-form";

  //pedido para criar device
  const {
    loading,
    refetch: createDevice,
  } = useCreate<IDevice>(ServiceApiUrl.deviceURL);

  // sucesso
  const handlerSuccess = () => {
    onClose && onClose(); //fecha modal
    addNotification({
      message: t("devices.deviceAdded"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  const formSubmitHandler = async (payLoad: IDeviceForm<string, number[]>) => {        
    try {          
      await createDevice({ data: payLoad });
      await handlerSuccess();
    } catch (error) {}
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        <Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
          {t("devices.addDevice")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DevicesForm formId={addFormId} onFormSubmit={formSubmitHandler} />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={false}
          type={"submit"}
          form={addFormId}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DevicesAdd;
