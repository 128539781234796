import { BackToButton, LayoutContext } from "@alb/live-lib";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import EntityInfo from "components/EntityInfo/EntityInfo";
import EntityInfoItem from "components/EntityInfo/EntityInfoItem";
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { TabPanelContainer } from "components/Utils/Tab/TabPanelContainer";
import { useGet } from "hooks/fetchData/useGet";
import { IAccount, ISelectOption } from "interfaces";
import { RoutePaths } from "routes/routes";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, formatDate, formatRoutePath } from "utils";

import AdaptersTabList from "../Adapters/AdaptersTabList";
import FilterSelect from "../Utils/FilterSelect";

const AccountsView = () => {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { addHeader } = useContext(LayoutContext);

  const { data: account, loading } = useGet<IAccount>(
    ServiceApiUrl.accountURL,
    params.id
  );

  const handleSelectedAccount = (option: ISelectOption | null) => {
    if (!option) return undefined;

    const path = formatRoutePath(location, params, {
      id: option.value,
    });

    navigate(path);
  };

  const header = {
    title: account?.name,
    backTo: <BackToButton title={t("common.goBack")} onClick={() => navigate(RoutePaths.ACCOUNTS)} />,
    select: (account && <FilterSelect<IAccount> apiUrl={ServiceApiUrl.accountURL} selectedValue={account?.id} onSelected={handleSelectedAccount} />),
  };

  useEffect(() => {
		if (!account?.name) return;
    addHeader(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.name]);

  let accountView = <NoData />;
  let adapters = <NoData />;

  if (loading) {
    accountView = <Loading />;
    adapters = <Loading />;
  }

  if (account) {
    accountView = (
      <EntityInfo title={t("common.details")}>
        <EntityInfoItem
          fitContent={true}
          label={t("accounts.form.nameLabel")}
          value={account.name}
        />
        <EntityInfoItem
          label={t("accounts.form.isActiveLabel")}
          value={account.is_active ? t("common.active") : t("common.inactive")}
        />
        <EntityInfoItem
          fitContent={true}
          label={t("accounts.form.descriptionLabel")}
          value={account.description}
        />
        <EntityInfoItem
          label={t("accounts.form.admin")}
          value={account.admin ? t("common.active") : t("common.inactive")}
        />
        <EntityInfoItem
          label={t("common.createdAt")}
          value={formatDate(
            account.created_at,
            t("calendar.dateTimeFormatLocal")
          )}
        />
        <EntityInfoItem
          label={t("common.updatedAt")}
          value={formatDate(
            account.updated_at,
            t("calendar.dateTimeFormatLocal")
          )}
        />
      </EntityInfo>
    );

    if (!arrayIsEmpty(account.adapter_configurators)) {
      adapters = (
        <AdaptersTabList adapters={account.adapter_configurators} />
      );
    }
  }

  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      {accountView}

      <Box mt={2}>
        <TabContext value={value}>
          <TabList variant="fullWidth" onChange={handleChange}>
            <Tab label={t("adapters.adapters")} value="1" />
          </TabList>

          <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
            <TabPanelContainer>{adapters}</TabPanelContainer>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default AccountsView;
