import React from "react";
import PropTypes from "prop-types";
import { Typography, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { valueEmptyTo } from "utils";
import { Link as RouterLink } from "react-router-dom";

const Item = (props: {
  label: string;
  value?: string | number;
  link?: string;
  fitContent?: boolean;
}) => {
  const { value, label, link, fitContent } = props;
  return (
    <Grid
      xs={12}
      sm={6}
      md={3}
      lg={2}
      minWidth={fitContent ? "fit-content" : "unset"}
    >
      <Typography variant={"subtitle2"} className="bold">
        {label}
      </Typography>

      {link ? (
        <Link underline="none" component={RouterLink} to={link}>
          {valueEmptyTo(value)}
        </Link>
      ) : (
        <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
          {valueEmptyTo(value)}
        </Typography>
      )}
    </Grid>
  );
};

const EntityInfoItem = (props: {
  label: string;
  value?: string | number;
  link?: string;
  fitContent?: boolean;
}) => {
  const { label, value, link, fitContent } = props;

  return (
    <Item label={label} value={value} link={link} fitContent={fitContent} />
  );
};

EntityInfoItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EntityInfoItem;
