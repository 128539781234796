import React, { useMemo } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { IAuth } from "interfaces/IAuth";
import { AUTH_KEY } from "utils";

interface IAuthContext {
  auth: IAuth | null;
  isLoggedIn: boolean;
  login: (auth: IAuth | null, cb?: Function) => void;
  logout: (cb?: VoidFunction) => void;
}
const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);

export const AuthContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [auth, setAuth] = useLocalStorage<IAuth | null>(AUTH_KEY, null);

  let userIsLoggedIn = !!auth;

  const logoutHandler = (cb?: Function) => {
    setAuth(null);
    cb && cb();
  };

  const loginHandler = async(auth: IAuth | null, cb?: Function) => {    
    setAuth(auth);
    cb && cb(auth);
  };

  const contextValue = useMemo(
    () => ({
      auth: auth,
      isLoggedIn: userIsLoggedIn,
      login: loginHandler,
      logout: logoutHandler,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
