import PartnersEdit from "./PartnersEdit";
import { BackToButton, LayoutContext } from "@alb/live-lib";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import EntityInfo from "components/EntityInfo/EntityInfo";
import EntityInfoItem from "components/EntityInfo/EntityInfoItem";
import FilterSelect from "components/Utils/FilterSelect";
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGet } from "hooks/fetchData/useGet";
import { IPartner, ISelectOption } from "interfaces";
import { RoutePaths } from "routes/routes";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate, formatRoutePath } from "utils";

const PartnersView = () => {
	let params = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { addHeader, addAction, action } = useContext(LayoutContext);

	const {
		data: partner,
		loading,
		refetch,
	} = useGet<IPartner>(ServiceApiUrl.partnerURL, params.id);

	const handleCloseEdit = () => {
		addAction(false);
	};
	const handleEditSuccess = () => {
		handleCloseEdit();

		// update list
		refetch();
	};

	const handleSelectedPartner = (option: ISelectOption | null) => {
		if (!option) return undefined;

		const path = formatRoutePath(location, params, {
			id: option.value,
		});

		navigate(path);
	};

	const header = {
		title: partner?.name,
		backTo: (
			<BackToButton title={t("common.goBack")} onClick={() => navigate(RoutePaths.PARTNERS)} />
		),
		select: (
			partner &&
			<FilterSelect<IPartner> apiUrl={ServiceApiUrl.partnerURL} selectedValue={partner?.id} onSelected={handleSelectedPartner} />
		),
		action: (
			<Button
				variant="contained"
				disableElevation
				startIcon={<EditIcon />}
				onClick={() => addAction(true)}
			>
				{t("partners.editPartner")}
			</Button>
		),
	};

	useEffect(() => {
		if (!partner?.name) return;
		addHeader(header);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partner?.name]);

	let partnerView = <NoData />;

	if (loading) {
		partnerView = <Loading />;
	}

	if (partner) {
		partnerView = (
			<EntityInfo title={t("common.details")}>
				<EntityInfoItem
					fitContent={true}
					label={t("accounts.form.nameLabel")}
					value={partner.name}
				/>
				<EntityInfoItem
					label={t("accounts.form.isActiveLabel")}
					value={partner.is_active ? t("common.active") : t("common.inactive")}
				/>
				<EntityInfoItem
					fitContent={true}
					label={t("accounts.form.descriptionLabel")}
					value={partner.description}
				/>

				<EntityInfoItem
					label={t("common.createdAt")}
					value={formatDate(
						partner.created_at,
						t("calendar.dateTimeFormatLocal")
					)}
				/>
				<EntityInfoItem
					label={t("common.updatedAt")}
					value={formatDate(
						partner.updated_at,
						t("calendar.dateTimeFormatLocal")
					)}
				/>
			</EntityInfo>
		);
	}

	let partnerEdit;
	if (action) {
		partnerEdit = (
			<PartnersEdit
				open={action}
				data={partner}
				onClose={handleCloseEdit}
				onSuccess={handleEditSuccess}
			/>
		);
	}

	return (
		<>
			{partnerView}
			{partnerEdit}
		</>
	);
};

export default PartnersView;
