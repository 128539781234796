import { InputAutocomplete, InputCheckbox, InputText } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  IAdapter,
  IApiResponse,
  IDevice,
  IDeviceForm,
  ISelectOption,
} from "interfaces";

import { formDefaultValues } from "./formDefaultValues";
import { getDeviceFormSchema } from "./validations";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils";

const DevicesForm = (props: {
  formId: string;
  data?: IDevice;
  onFormSubmit: (payLoad: IDeviceForm<string, number[]>) => void;
}) => {
  const { t } = useTranslation();

  const { formId, data, onFormSubmit } = props;

  const defaultValues = formDefaultValues(data);
  const defaultSelect: ISelectOption[] = [];

  // get list of adapters
  const { data: adapters, loading } = useGetAll<IApiResponse<IAdapter>>(
    ServiceApiUrl.adapterURL
  );

  const [adaptersOptions, setAdaptersOptions] =
    useState<ISelectOption[]>(defaultSelect);
  useEffect(() => {
    if (adapters) {
      const aOpts = adapters.data.map((p: IAdapter) => ({
        label: p.name,
        value: p.id,
      }));
      setAdaptersOptions(aOpts);
    }
  }, [adapters]);

  const methodsForm = useForm<IDeviceForm>({
    defaultValues: defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(getDeviceFormSchema(t("validations.required"))),
  });

  const { control, handleSubmit, setValue, setError } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IDeviceForm> = (
    payLoad: IDeviceForm
  ) => {
    const payLoadAPI = payLoad as unknown as IDeviceForm<string, number[]>;

		try{
			payLoadAPI.extra_params = payLoad.extra_params ? JSON.parse(payLoad.extra_params): {}
		}
		catch{
			setError('extra_params', { type: 'custom', message: t("common.invalidJSONFormat") });
			return false;
		}

    const geo: [number, number] = [
      Number(payLoad.latitude),
      Number(payLoad.longitude),
    ];
    payLoadAPI.geolocation = geo;

    payLoadAPI.adapter_configurators = payLoad.adapter_configurators.map(
      (a: ISelectOption) => a.value
    );

    onFormSubmit(payLoadAPI);
  };

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("devices.form.nameLabel")}
              </Typography>
              <InputText
                control={control}
                autoFocus={true}
                name="name"
                placeholder={t("devices.form.namePlaceholder")}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("devices.form.externalIDLabel")}
              </Typography>
              <InputText
                control={control}
                name="external_id"
                placeholder={t("devices.form.externalIDPlaceholder")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("devices.form.descriptionLabel")}
              </Typography>
              <InputText
                control={control}
                name="description"
                placeholder={t("devices.form.descriptionPlaceholder")}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("devices.form.latitudeLabel")}
              </Typography>
              <InputText
                control={control}
                name="latitude"
                placeholder={t("devices.form.latitudePlaceholder")}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("devices.form.longitudeLabel")}
              </Typography>
              <InputText
                control={control}
                name="longitude"
                placeholder={t("devices.form.longitudePlaceholder")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("devices.form.extraParamsLabel")}
              </Typography>
              <InputText
                control={control}
                name="extra_params"
                placeholder={t("devices.form.extraParamsPlaceholder")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("devices.form.adapterConfiguratorsLabel")}
              </Typography>
              {!loading && !arrayIsEmpty(adaptersOptions) && (
                <InputAutocomplete
                  control={control}
                  name="adapter_configurators"
                  options={adaptersOptions}
                  placeholder={t(
                    "devices.form.adapterConfiguratorsPlaceholder"
                  )}
                  multiple
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("devices.form.isActiveLabel")}
              </Typography>
              <InputCheckbox
                control={control}
                name="is_active"
                setValue={setValue}
                checked={defaultValues.is_active}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default DevicesForm;
