import { InputCheckbox, InputAutocomplete, InputText } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { useGetAll } from "hooks/fetchData/useGetAll";
import {
  IAccountForm,
  IAdapter,
  IApiResponse,
  ISelectOption  
} from "interfaces";
import React, { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils";
import { formDefaultValues } from "./formDefaultValues";
import { getAccountFormSchema } from "./validations";

const AccountsForm = (props: {
  formId: string;
  onFormSubmit: (payLoad: IAccountForm<string>) => void;
}) => {
  const { t } = useTranslation();

  const { formId, onFormSubmit } = props;

  const defaultSelect: ISelectOption[] = [];

  // get list of adapters
  const {
    data: adapters,
    loading,
  } = useGetAll<IApiResponse<IAdapter>>(ServiceApiUrl.adapterURL);

  const [adaptersOptions, setAdaptersOptions] =
    useState<ISelectOption[]>(defaultSelect);
  useEffect(() => {
    if (adapters) {
      const aOpts = adapters.data.map((p: IAdapter) => ({
        label: p.name,
        value: p.id,
      }));
      setAdaptersOptions(aOpts);
    }
  }, [adapters]);

  const defaultValues = formDefaultValues();

  const methodsForm = useForm<IAccountForm>({
    defaultValues: defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(getAccountFormSchema(t("validations.required"))),
  });

  const { control, handleSubmit, setValue } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IAccountForm> = (payLoad: IAccountForm) => {

    const payLoadAPI = payLoad as unknown as IAccountForm<string>;
    payLoadAPI.adapter_configurators = payLoad.adapter_configurators.map((a:ISelectOption) => a.value);

    onFormSubmit(payLoadAPI);
  };

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("accounts.form.nameLabel")}
              </Typography>
              <InputText
                control={control}
                autoFocus={true}
                name="name"
                placeholder={t("accounts.form.namePlaceholder")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("accounts.form.descriptionLabel")}
              </Typography>
              <InputText
                control={control}
                name="description"
                placeholder={t("accounts.form.descriptionPlaceholder")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("accounts.form.adapterConfiguratorsLabel")}
              </Typography>
              {!loading && !arrayIsEmpty(adaptersOptions) && (                
                <InputAutocomplete
                  control={control}
                  name="adapter_configurators"
                  options={adaptersOptions}
                  placeholder={t("accounts.form.adapterConfiguratorsPlaceholder")}
                  multiple
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("accounts.form.isActiveLabel")}
              </Typography>
              <InputCheckbox
                control={control}
                name="is_active"
                setValue={setValue}
                checked={defaultValues.is_active}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("accounts.form.admin")}
              </Typography>
              <InputCheckbox
                control={control}
                name="admin"
                setValue={setValue}
                checked={defaultValues.admin}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default AccountsForm;
