import { InputCheckbox, InputSelect, InputText } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { useGetAll } from "hooks/fetchData/useGetAll";
import {
  IAdapter,
  IAdapterForm,
  IApiResponse,
  IPartner,
  ISelectOption,
  ITenant,
} from "interfaces";
import React, { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils";
import { formDefaultValues } from "./formDefaultValues";
import { getAdapterFormSchema } from "./validations";

const AdaptersAddForm = (props: {
  formId: string;
  data?: IAdapter;
  onFormSubmit: (payLoad: IAdapterForm) => void;
}) => {
  const { t } = useTranslation();

  const { formId, data, onFormSubmit } = props;

  const defaultSelect: ISelectOption[] = [];

  // get list of tenants
  const {
    data: tenants,
    loading: loadingTenants,
  } = useGetAll<IApiResponse<ITenant>>(ServiceApiUrl.tenantURL);

  // get list of partners
  const {
    data: partners,
    loading: loadingPartners,
  } = useGetAll<IApiResponse<IPartner>>(ServiceApiUrl.partnerURL);

  const [partnersOptions, setPartnersOptions] =
    useState<ISelectOption[]>(defaultSelect);
  useEffect(() => {
    if (partners) {
      const pOpts = partners.data.map((p: IPartner) => ({
        label: p.name,
        value: p.id,
      }));
      setPartnersOptions(pOpts);
    }
  }, [partners]);

  const [tenantsOptions, setTenantsOptions] =
    useState<ISelectOption[]>(defaultSelect);
  useEffect(() => {
    if (tenants) {
      const tOpts = tenants.data.map((p: ITenant) => ({
        label: p.name,
        value: p.id,
      }));
      setTenantsOptions(tOpts);
    }
  }, [tenants]);

  const defaultValues = formDefaultValues(data);

  const methodsForm = useForm<IAdapterForm>({
    defaultValues: defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(getAdapterFormSchema()),
  });

  const { control, handleSubmit, setValue } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IAdapterForm> = (payLoad: IAdapterForm) => {
    onFormSubmit(payLoad);
  };

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.nameLabel")}
              </Typography>
              <InputText
                control={control}
                autoFocus={true}
                name="name"
                placeholder={t("adapters.form.namePlaceholder")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.descriptionLabel")}
              </Typography>
              <InputText
                control={control}
                name="description"
                placeholder={t("adapters.form.descriptionPlaceholder")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.gatewayUrlLabel")}
              </Typography>
              <InputText
                control={control}
                name="gateway_url"
                placeholder={t("adapters.form.gatewayUrlPlaceholder")}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.gatewayUserLabel")}
              </Typography>
              <InputText
                control={control}
                name="gateway_user"
                placeholder={t("adapters.form.gatewayUserPlaceholder")}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.gatewayPasswordLabel")}
              </Typography>
              <InputText
                control={control}
                type="password"
                name="gateway_password"
                placeholder={t("adapters.form.gatewayPasswordPlaceholder")}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.domainLabel")}
              </Typography>
              <InputText
                control={control}
                name="domain"
                placeholder={t("adapters.form.domainPlaceholder")}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.domainDevoLabel")}
              </Typography>
              <InputText
                control={control}
                name="domain_devo"
                placeholder={t("adapters.form.domainDevoPlaceholder")}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.partnerDevoLabel")}
              </Typography>
              <InputText
                control={control}
                name="partner_devo"
                placeholder={t("adapters.form.partnerDevoPlaceholder")}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.partnerLabel")}
              </Typography>
              {!loadingPartners && !arrayIsEmpty(partnersOptions) && (
                <InputSelect
                  control={control}
                  name="partner"
                  options={partnersOptions}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.tenantLabel")}
              </Typography>
              {!loadingTenants && !arrayIsEmpty(tenantsOptions) && (
                <InputSelect
                  control={control}
                  name="tenant"
                  options={tenantsOptions}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.isActiveLabel")}
              </Typography>
              <InputCheckbox
                control={control}
                name="is_active"
                setValue={setValue}
                checked={defaultValues.is_active}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h6">
                {t("adapters.form.protectedIngestionAtLabel")}
              </Typography>
              <InputCheckbox
                control={control}
                name="protected_ingestion"
                setValue={setValue}
                checked={defaultValues.protected_ingestion}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default AdaptersAddForm;
